/** @format */
import {
  TNotification,
  TNotificationPopupInfo,
} from "../../redux-modules/declarations/maps/notifications";
import {
  PermissionAction,
  PermissionModel,
} from "../../redux-modules/declarations/maps/permissions";
import { Routes } from "../Routes";
import userIcon from "../../assets/images/curriculum.png";
import requestIcon from "../../assets/images/calendar.png";
import documentIcon from "../../assets/images/document.png";
import assignmentIcon from "../../assets/images/assegnazione.png";

export const utilityNotificationToNotificationPopupInfo = (
  notificationList: TNotification[],
  history: unknown,
  user_id?: string,
): TNotificationPopupInfo[] => {
  return notificationList.map(notification => {
    return {
      key: notification.id,
      id: notification.id,
      read_at: notification.read_at,
      title: notification.title,
      description: notification.description,
      avatar: getNotificationIconByModelAndAction(notification.action, notification.model),
      onClick: getOnClickByModelAndAction(
        notification.action,
        notification.model,
        notification.model_id,
        history,
        user_id,
      ),
    };
  });
};

export const getNotificationIconByModelAndAction = (
  action: PermissionAction,
  model: PermissionModel,
): string => {
  //return "https://cdn-icons-png.flaticon.com/512/3845/3845879.png";
  switch (model) {
    case "User":
      switch (action) {
        case "Create":
          return userIcon;
        case "Update":
          return userIcon;
        case "Delete":
          return userIcon;
        default:
          return userIcon;
      }
    case "Document":
      switch (action) {
        case "Create":
          return documentIcon;
        case "Update":
          return documentIcon;
        case "Delete":
          return documentIcon;
        default:
          return documentIcon;
      }
    case "Request":
      switch (action) {
        case "Create":
          return requestIcon;
        case "Update":
          return requestIcon;
        case "Delete":
          return requestIcon;
        default:
          return requestIcon;
      }
    case "Warehouse":
      switch (action) {
        case "Create":
          return assignmentIcon;
        case "Assignment":
          return assignmentIcon;
        case "Update":
          return assignmentIcon;
        case "Delete":
          return assignmentIcon;
      }
    case "Communication":
      switch (action) {
        case "Create":
          return "https://cdn-icons-png.flaticon.com/512/3845/3845879.png";
        case "Update":
          return "https://cdn-icons-png.flaticon.com/512/3845/3845879.png";
        case "Delete":
          return "https://cdn-icons-png.flaticon.com/512/3845/3845879.png";
      }
    default:
      return "https://cdn-icons-png.flaticon.com/512/3845/3845879.png";
  }
};

export const getOnClickByModelAndAction = (
  action: PermissionAction,
  model: PermissionModel,
  model_id: string,
  history: unknown,
  userId: string | undefined,
): (() => void) => {
  switch (model) {
    case "User":
      switch (action) {
        case "Create":
          return (): void => {
            //@ts-ignore
            history.push(Routes.usersEdit.replace(":id_user", model_id));
          };
        case "Update":
          return (): void => {
            //@ts-ignore
            history.push(Routes.usersEdit.replace(":id_user", model_id));
          };
        case "Delete":
          return (): void => {
            //@ts-ignore
            history.push(Routes.users);
          };
        default:
          return (): void => {
            console.log("no action");
          };
      }
    case "Document":
      switch (action) {
        case "Create":
          return (): void => {
            //@ts-ignore
            history.push(Routes.usersEditDocuments.replace(":id_user", model_id));
          };
        case "Delete":
          return (): void => {
            //@ts-ignore
            history.push(Routes.usersEditDocuments.replace(":id_user", model_id));
          };
        default:
          return (): void => {
            console.log("no action");
          };
      }
    case "Request":
      switch (action) {
        case "Create":
          return (): void => {
            //@ts-ignore
            history.push(Routes.requests);
          };
        case "Update":
          return (): void => {
            //@ts-ignore
            history.push(Routes.requests);
          };
        case "Delete":
          return (): void => {
            //@ts-ignore
            history.push(Routes.requests);
          };
        default:
          return (): void => {
            console.log("no action");
          };
      }
    case "Warehouse":
      switch (action) {
        case "Create":
          return (): void => {
            //@ts-ignore
            history.push(Routes.usersEditAssignments.replace(":id_user", userId));
          };
        case "Assignment":
          return (): void => {
            //@ts-ignore
            history.push(Routes.usersEditAssignments.replace(":id_user", userId));
          };
        case "Update":
          return (): void => {
            //@ts-ignore
            history.push(Routes.usersEditAssignments.replace(":id_user", userId));
          };
        case "Delete":
          return (): void => {
            //@ts-ignore
            history.push(Routes.requests);
          };
        default:
          return (): void => {
            console.log("no action");
          };
      }
    case "Communication":
      switch (action) {
        case "Create":
          return (): void => {
            //@ts-ignore
            history.push(Routes.usersEditCommunications.replace(":id_user", userId));
          };
        case "Update":
          return (): void => {
            //@ts-ignore
            history.push(Routes.usersEditCommunications.replace(":id_user", userId));
          };
        case "Delete":
          return (): void => {
            //@ts-ignore
            history.push(Routes.requests);
          };
        default:
          return (): void => {
            console.log("no action");
          };
      }
    default:
      return (): void => {
        console.log("no action");
      };
  }
};
