/** @format */

import { Col, Drawer, Form, Row, Skeleton } from "antd";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_ENDPOINTS } from "../../../../constants/api";
import { actionPresenceCreateSuccess } from "../../../../redux-modules/actions/actionsPresences";
import { TUser } from "../../../../redux-modules/declarations/maps/auth";
import { TPresenceType } from "../../../../redux-modules/declarations/maps/presenceTypes";
import { TShiftType } from "../../../../redux-modules/declarations/maps/shiftTypes";
import { selectorPermissionList } from "../../../../redux-modules/selectors/selectorsPermissions";
import { selectorPresenceTypeList } from "../../../../redux-modules/selectors/selectorsPresenceTypes";
import { selectorShiftTypeListWorkable } from "../../../../redux-modules/selectors/selectorsShiftTypes";
import apiClient from "../../../../utils/apiClient";
import { showNotification } from "../../../../utils/showNotification";
import { UIButton } from "../../../ui/Button";
import { UICard } from "../../../ui/Card";
import { UIDatePicker } from "../../../ui/DatePicker";
import { UISelect } from "../../../ui/Select";
const { Option } = UISelect;

export interface TPresenceCreateObject {
  presence_type_id?: string;
  user_id: string;
  datetime_from: moment.Moment;
  datetime_to?: moment.Moment;
  shift_type_id?: string;
}

interface Props {
  visible: boolean;
  currentDate: moment.Moment;
  users: TUser[] | null;
  onClose: (refresh?: boolean) => void;
  modalLoading?: boolean;
  initialValues: TPresenceCreateObject | null;
  updateAbsences: () => void;
}

export const CreatePresenceDrawer: FC<Props> = ({
  visible,
  users,
  onClose,
  modalLoading,
  initialValues,
  updateAbsences,
  currentDate,
}: Props): JSX.Element => {
  //@ts-ignore
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const presenceTypes = useSelector(selectorPresenceTypeList);
  const shiftTypeList = useSelector(selectorShiftTypeListWorkable);
  const [showDatetimeTo, setShowDatetimeTo] = useState(false);

  const handleClose = () => {
    if (onClose)
      // quello che vogliamo
      onClose();
    form.resetFields();
  };

  const onChangeForm = () => {
    if (form && users) {
      const userSelected = form.getFieldValue("user_id");

      if (users.find(e => e.id === userSelected)?.is_single_badge) {
        setShowDatetimeTo(false);
      } else {
        setShowDatetimeTo(true);
      }
    }
  };

  const onSave = () => {
    const currentDateFormatted = currentDate.format("YYYY-MM-DD");
    const presenceDateFormatted = form.getFieldValue("datetime_from").format("YYYY-MM-DD");

    form.validateFields().then(values => {
      const obj: TPresenceCreateObject = {
        presence_type_id: form.getFieldValue("presence_type_id"),
        user_id: form.getFieldValue("user_id"),
        datetime_from: form.getFieldValue("datetime_from").format("YYYY-MM-DD HH:mm:ss"),
        datetime_to: form.getFieldValue("datetime_to")?.format("YYYY-MM-DD HH:mm:ss"),
        shift_type_id: form.getFieldValue("shift_type_id"),
      };
      apiClient
        .post(API_ENDPOINTS.PRESENCES + "/create_artificial", obj)
        .then(res => {
          showNotification("success", "Presenza salvata", "Presenza salvata con successo!");
          form.resetFields();
          // OPERARE SUL REDUCER
          if (currentDateFormatted == presenceDateFormatted) {
            dispatch(actionPresenceCreateSuccess(res.data.data));
            updateAbsences();
          } else {
            console.log("non aggiorno");
          }

          if (onClose) {
            onClose(true);
          }
        })
        .catch(err => {
          console.log(err);
          form.resetFields();
          showNotification(
            "error",
            "Errore", //@ts-ignore
            err.response.data.errors
              ? //@ts-ignore
                Object.values(err.response.data.errors).join(`\n`)
              : //@ts-ignore
                err.response.data.message,
          );
        });
    });
  };

  useEffect(() => {
    if (!!users && !!initialValues) {
      !!users.find(e => e.id === initialValues.user_id)?.is_single_badge
        ? setShowDatetimeTo(false)
        : setShowDatetimeTo(true);
    }
    if (!!initialValues) {
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [users, initialValues]);

  if (!users || !presenceTypes || !shiftTypeList)
    return (
      <Drawer
        title="Nuova Presenza"
        placement="right"
        onClose={handleClose}
        closable={true}
        visible={visible}
        width={400}
      >
        <UICard style={{ marginBottom: "20px", border: "none" }}>
          <Skeleton active />
        </UICard>
      </Drawer>
    );

  return (
    <Drawer
      title="Nuova Presenza"
      placement="right"
      onClose={handleClose}
      closable={true}
      visible={visible}
      width={400}
      footer={
        <Row justify="space-between">
          <Col>
            <Form.Item name="submit">
              <UIButton
                form="form"
                key="submit"
                htmlType="submit"
                type="primary"
                loading={!users || !presenceTypes || !shiftTypeList}
              >
                Salva
              </UIButton>
            </Form.Item>
          </Col>
          <Col>
            <UIButton onClick={() => onClose()}>Annulla</UIButton>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col span={24}>
          <>
            <Form
              id="form"
              initialValues={initialValues ?? undefined}
              form={form}
              onValuesChange={onChangeForm}
              onFinish={onSave}
            >
              <Row>
                <Col span={24}>
                  <Row style={{ marginBottom: "5px" }}>
                    <Col span={24}>
                      <Form.Item
                        name="user_id"
                        label="Utente"
                        rules={[{ required: true, message: "Seleziona un utente" }]}
                      >
                        <UISelect
                          loading={!users}
                          showSearch
                          filterOption={(input, option) =>
                            ((option!.children as unknown) as string)
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                        >
                          {!!users &&
                            users.map((user: TUser) => (
                              <Option value={user.id} key={user.id}>
                                {user.surname + " " + user.name}
                              </Option>
                            ))}
                        </UISelect>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "5px" }}>
                    <Col span={24} style={{ display: "inline-flex", alignItems: "center" }}>
                      <Form.Item
                        name="datetime_from"
                        label="Dalle"
                        rules={[{ required: true, message: "Indica una data di inizio" }]}
                      >
                        <UIDatePicker
                          format="DD/MM/YYYY HH:mm"
                          //@ts-ignore
                          showTime
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  {showDatetimeTo && (
                    <Row style={{ marginBottom: "5px" }}>
                      <Col span={24} style={{ display: "inline-flex", alignItems: "center" }}>
                        <Form.Item
                          name="datetime_to"
                          label="Alle"
                          rules={[{ required: true, message: "Indica una data di fine" }]}
                        >
                          <UIDatePicker
                            format="DD/MM/YYYY HH:mm"
                            //@ts-ignore
                            showTime
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={24}>
                  <Form.Item
                    name="presence_type_id"
                    label="Tipologia Presenza"
                    rules={[{ required: true, message: "Indica una tipologia" }]}
                  >
                    <UISelect loading={!presenceTypes}>
                      {!!presenceTypes &&
                        presenceTypes.map((ptype: TPresenceType) => (
                          <Option value={ptype.id} key={ptype.id}>
                            {ptype.name}
                          </Option>
                        ))}
                    </UISelect>
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ marginBottom: "5px" }}>
                <Col span={24}>
                  <Form.Item
                    name="shift_type_id"
                    label="Tipologia Turno"
                    rules={[{ required: true, message: "Seleziona un turno" }]}
                  >
                    <UISelect
                      loading={!shiftTypeList}
                      showSearch
                      filterOption={(input, option): boolean =>
                        ((option!.children as unknown) as string)
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    >
                      {!!shiftTypeList &&
                        shiftTypeList.map((shift: TShiftType) => (
                          <Option value={shift.id} key={shift.id}>
                            {shift.name}
                          </Option>
                        ))}
                    </UISelect>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </>
        </Col>
      </Row>
    </Drawer>
  );
};
