/** @format */

import {
  CheckCircleFilled,
  CheckCircleOutlined,
  CheckCircleTwoTone,
  ClockCircleTwoTone,
  ExclamationCircleTwoTone,
  LeftCircleOutlined,
  RightCircleOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Avatar, Cascader, Col, Collapse, DatePicker, Row, Skeleton } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { API_ENDPOINTS } from "../../../constants/api";
import { TRequest } from "../../../redux-modules/declarations/maps/requests";
import { useRequests } from "../../../redux-modules/hooks/useRequests";
import { selectorIsUser, selectorLoggedUser } from "../../../redux-modules/selectors/selectorsAuth";
import { selectorGroupsList } from "../../../redux-modules/selectors/selectorsGroups";
import { selectorJustificativeList } from "../../../redux-modules/selectors/selectorsJustificatives";
import { selectorJustificativeStatusesList } from "../../../redux-modules/selectors/selectorsJustificativeStatuses";
import { selectorUsersListLoading } from "../../../redux-modules/selectors/selectorsUI";
import { BaseColor } from "../../../theme/maps/Colors";
import apiClient from "../../../utils/apiClient";
import { utilityGetDatesSiblingsToOneDate } from "../../../utils/format/rangeBetweenTwoDates";
import { hexToRgb } from "../../../utils/styles";
import { UICard } from "../../ui/Card";
import { UIText } from "../../ui/Text";
import { UITitle } from "../../ui/Title";
import UITransition from "../../ui/Transition";
import { IndexDay } from "./partials/IndexDay";
import { RequestDetailDrawer } from "./partials/RequestDetailDrawer";
const { Panel } = Collapse;

export const RequestCalendar = (): JSX.Element => {
  const [showModal, setShowModal] = useState(false);
  const [openedGroups, setOpenedGroups] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [activeDay, setActiveDay] = useState(moment());
  const [datesRange, setDatesRange] = useState(utilityGetDatesSiblingsToOneDate(moment().toDate()));
  const loggedUser = useSelector(selectorLoggedUser);
  const [activeRequest, setActiveRequest] = useState<TRequest | null>(null);
  const [filteredStatuses, setFilteredStatuses] = useState<string[]>([]);
  const [filteredGroups, setFilteredGroups] = useState<string[]>([
    ...(loggedUser?.groups?.map(e => e.id) || []),
  ]);
  const [filteredJustificativeTypes, setFilteredJustificativeTypes] = useState<string[]>([]);

  const {
    requestList,
    isRequestListLoading,
    requestsOwnersUnique,
    updateRequestList,
  } = useRequests(datesRange);

  const usersLoading = useSelector(selectorUsersListLoading);
  const groups = useSelector(selectorGroupsList);
  const justificativeStatuses = useSelector(selectorJustificativeStatusesList);
  const justificativeTypes = useSelector(selectorJustificativeList);

  const [blockPeriods, setBlockPeriods] = useState<string[]>([]);
  const isUser = useSelector(selectorIsUser);

  useEffect(() => {
    apiClient.get(API_ENDPOINTS.BLOCK_MONTHS).then(({ data }) => {
      setBlockPeriods(data);
    });
  }, []);

  const hideToUser = (request_from: moment.Moment, request_to: moment.Moment) => {
    if (isUser) {
      var list: string[] = [];

      list.push(request_from.format("YYYY-MM-DD"));
      list.push(request_to.format("YYYY-MM-DD"));
      for (var current = request_from; current <= request_to; current.add(1, "d")) {
        list.push(current.format("YYYY-MM-DD"));
      }

      if (blockPeriods.some(e => list.includes(e))) {
        return true;
      }
    }
    return false;
  };

  const filters = [
    {
      label: "Stati",
      value: "statuses",
      children: justificativeStatuses.map(status => ({ label: status.name, value: status.id })),
    },
    {
      label: "Gruppi",
      value: "groups",
      children: groups.data.map(group => ({
        label: group.name,
        value: group.id,
        children: usersLoading
          ? []
          : requestsOwnersUnique
              .filter(user => user.user.groups.map(g => g.id).includes(group.id))
              .map(user => ({
                label: user.user.surname + " " + user.user.name,
                value: user.user.id,
              })),
      })),
    },
    {
      label: "Tipologia",
      value: "justificative_type",
      children: justificativeTypes.data.map(type => ({ label: type.name, value: type.id })),
    },
  ];

  const onChangeFilters = (value: string[][]) => {
    const statusesArray = value.filter(e => e[0] === "statuses");
    const groupsArray = value.filter(e => e[0] === "groups");
    const typesArray = value.filter(e => e[0] === "justificative_type");

    let selectedStatuses: string[] = [];
    let selectedGroups: string[] = [];
    let selectedUsers: string[] = [];
    let selectedJustificativeType: string[] = [];

    if (statusesArray.length > 0) {
      if (statusesArray.length == 1) {
        if (!!statusesArray[0][1]) {
          selectedStatuses = [statusesArray[0][1]];
        }
      } else {
        selectedStatuses = statusesArray.map(e => e[1]);
      }
    }

    if (typesArray.length > 0) {
      if (typesArray.length == 1) {
        if (!!typesArray[0][1]) {
          selectedJustificativeType = [typesArray[0][1]];
        }
      } else {
        selectedJustificativeType = typesArray.map(e => e[1]);
      }
    }

    if (groupsArray.length > 0) {
      if (groupsArray.length == 1) {
        if (!!groupsArray[0][1]) {
          selectedGroups = [groupsArray[0][1]];
          if (!!groupsArray[0][2]) {
            selectedUsers = [groupsArray[0][2]];
          }
        }
      } else {
        selectedGroups = groupsArray.map(e => e[1]);
        selectedUsers = groupsArray.filter(e => e.length > 2).map(e => e[2]);
      }
    }

    setFilteredStatuses(selectedStatuses);
    setFilteredGroups(selectedGroups);
    setFilteredJustificativeTypes(selectedJustificativeType);
  };

  const getRequestByIndex = (index: number, userId: string): TRequest | undefined => {
    const date = datesRange[index];

    return requestList.find(({ datetime_from, datetime_to, user_id }) => {
      if (userId === user_id) {
        const start = moment(
          moment(datetime_from, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY 00:00"),
          "DD/MM/YYYY HH:mm",
        );
        const end = moment(
          moment(datetime_to, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY 23:59"),
          "DD/MM/YYYY HH:mm",
        );
        const now = moment(date).add(1, "minute");
        return start <= now && end > now;
      }
    });
    /*
    const requestFound = requestList
      .sort((a, b) => {
        return moment(b.data_creazione_richiesta, "DD/MM/YYYY HH:mm").diff(
          moment(a.data_creazione_richiesta, "DD/MM/YYYY HH:mm"),
        );
      })
      .find(el => {
        const fixFrom =
          moment(el.datetime_from, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY") + " 00:00";
        const fixTo = moment(el.datetime_to, "DD/MM/YYYY HH:mm").format("DD/MM/YYYY") + " 23:59";
        return (
          moment(date)
            .add(1, "minute")
            .isBetween(moment(fixFrom, "DD/MM/YYYY HH:mm"), moment(fixTo, "DD/MM/YYYY HH:mm")) &&
          el.user_id === userId
        );
      });
    return requestFound;*/
  };

  const goBack = () => setActiveDay(moment(activeDay).subtract(1, "days"));
  const goNext = () => setActiveDay(moment(activeDay).add(1, "days"));
  const goToday = () => setActiveDay(moment());
  //@ts-ignore
  const goTo = date => setActiveDay(date || moment());

  useEffect(() => {
    setDatesRange(utilityGetDatesSiblingsToOneDate(activeDay.toDate()));
    updateRequestList(utilityGetDatesSiblingsToOneDate(activeDay.toDate()));
  }, [activeDay]);

  const handleOpenModal = (id: string) => {
    const request = requestList.find(el => el.id === id);
    if (request) {
      setShowModal(true);
      setModalLoading(true);
      apiClient
        .get(API_ENDPOINTS.REQUESTS + `/${request.id}`)
        .then(res => {
          setActiveRequest(res.data.data);
          setModalLoading(false);
        })
        .catch(err => {});
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setActiveRequest(null);
  };

  return (
    <UITransition>
      <Row>
        <Col span={24}>
          <Row>
            <Col span={6}></Col>
            <Col span={16} style={{ textAlign: "center" }}>
              <UITitle level={4} style={{ textTransform: "capitalize" }} color={BaseColor}>
                {activeDay.format("MMMM YYYY")}
              </UITitle>
            </Col>
            <Col span={4}></Col>
          </Row>
          <Row style={{ marginBottom: "10px" }}>
            <Col span={4}></Col>
            <Col span={2}></Col>
            <Col span={16} style={{ display: "flex", justifyContent: "center" }}>
              <span
                onClick={goBack}
                style={{ fontSize: "22px", cursor: "pointer", color: BaseColor }}
              >
                <LeftCircleOutlined />
              </span>
              <CustomDatePicker
                onChange={goTo}
                allowClear={false}
                format={"dddd DD"}
                value={activeDay}
              />
              <span
                onClick={goNext}
                style={{ fontSize: "22px", cursor: "pointer", color: BaseColor }}
              >
                <RightCircleOutlined />
              </span>
            </Col>
            <Col span={2} style={{ textAlign: "right" }}></Col>
          </Row>
          <Row>
            <StyledSpan className="container_calenda">
              <Row className="giorni">
                <Col span={4}>
                  {isRequestListLoading ? (
                    <span style={{ height: "20px" }}>
                      <Skeleton.Input
                        active
                        style={{ marginRight: "20px", paddingRight: "30px" }}
                      />
                    </span>
                  ) : (
                    <Cascader
                      style={{ width: "100%" }}
                      showSearch
                      options={filters}
                      defaultValue={
                        filteredGroups.length ? [...filteredGroups.map(e => ["groups", e])] : []
                      }
                      //@ts-ignore
                      onChange={onChangeFilters}
                      multiple
                      maxTagCount="responsive"
                      placeholder={
                        <span>
                          <SearchOutlined style={{ marginRight: "5px" }} /> Filtra richieste
                        </span>
                      }
                    />
                  )}
                </Col>
                <Col span={20}>
                  <Row>
                    {datesRange.map(date => (
                      <IndexDay key={date} date={date} activeDay={activeDay} />
                    ))}
                  </Row>
                </Col>
              </Row>
              {isRequestListLoading ? (
                <UICard style={{ marginBottom: "20px", border: "none" }}>
                  <Skeleton active />
                </UICard>
              ) : requestList.length > 0 &&
                (!filteredGroups.length ||
                  requestsOwnersUnique.filter(user =>
                    user.user.groups.map(g => g.id).some(g => filteredGroups.includes(g)),
                  ).length) ? (
                <StyledCollapse
                  //@ts-ignore
                  onChange={e => setOpenedGroups(e)}
                  activeKey={openedGroups}
                  className="calenda"
                  bordered={false}
                  style={{ borderRadius: "7px" }}
                >
                  {groups.data
                    .filter(g => (!filteredGroups.length ? true : filteredGroups.includes(g.id)))
                    .map((group, i) => {
                      if (
                        !!requestsOwnersUnique.filter(usr =>
                          usr.user.groups.map(e => e.id).includes(group.id),
                        ).length
                      )
                        return (
                          <CustomPanel
                            header={
                              <Row>
                                <Col
                                  span={4}
                                  data-side-left
                                  style={{ borderRight: "1px solid #bcbcbc", fontSize: "14px" }}
                                >
                                  {group.name}{" "}
                                  <span
                                    className="ant-collapse-expand-icon"
                                    style={{ fontWeight: "bold" }}
                                  >
                                    <span
                                      role={"img"}
                                      className={"anticon anticon-right ant-collapse-arrow buono"}
                                    >
                                      <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        data-icon="right"
                                        width="1em"
                                        height="1em"
                                        fill="currentColor"
                                        aria-hidden="true"
                                        style={{
                                          //@ts-ignore
                                          transform: openedGroups.includes(group.id)
                                            ? ""
                                            : "rotate(90deg)",
                                        }}
                                      >
                                        <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                                      </svg>
                                    </span>
                                    {/*
                                    requestsOwnersUnique.filter(usr =>
                                      usr.user.groups.map(e => e.id).includes(group.id),
                                    ).length*/}
                                  </span>
                                </Col>
                                <Col
                                  span={20}
                                  data-side-right
                                  onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                  }}
                                >
                                  <Row data-days-cells>
                                    {Array.from(Array(24).keys()).map(c => (
                                      <Col
                                        span={1}
                                        key={c}
                                        style={{
                                          background:
                                            "repeating-linear-gradient(-45deg, rgb(240 237 237), rgb(234 231 231) 2px, rgb(229, 229, 247) 3px, rgb(229, 229, 247) 4px)",
                                        }}
                                      >
                                        {"\u00A0"}
                                      </Col>
                                    ))}
                                  </Row>
                                </Col>
                              </Row>
                            }
                            key={group.id}
                          >
                            {requestsOwnersUnique
                              .filter(usr => usr.user.groups.map(e => e.id).includes(group.id))
                              .map(owner => (
                                <Row key={owner.user.id}>
                                  <Col
                                    span={4}
                                    data-side-left
                                    data-user
                                    style={{
                                      borderRight: "1px solid #bcbcbc",
                                      display: "flex",
                                    }}
                                  >
                                    <Avatar
                                      src={owner.user.avatar}
                                      style={{
                                        marginRight: "10px",
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                    <UIText style={{ fontWeight: 600, fontSize: "14px" }}>
                                      {owner.user.surname} {owner.user.name}
                                    </UIText>
                                  </Col>
                                  <Col
                                    span={20}
                                    data-side-right
                                    data-user
                                    style={{ borderTop: "1px solid #bcbcbc" }}
                                  >
                                    <Row data-days-cells>
                                      {Array.from(Array(24).keys()).map(c => {
                                        const request = getRequestByIndex(c, owner.user.id);
                                        const isStart =
                                          moment(datesRange[c]).format("DD-MM-YYYY") ==
                                          moment(request?.datetime_from, "DD/MM/YYYY HH:mm").format(
                                            "DD-MM-YYYY",
                                          );

                                        const isEnd: boolean =
                                          moment(datesRange[c]).format("DD-MM-YYYY") ==
                                          moment(request?.datetime_to, "DD/MM/YYYY HH:mm").format(
                                            "DD-MM-YYYY",
                                          );

                                        if (
                                          !request ||
                                          (!!filteredStatuses.length &&
                                            !filteredStatuses.includes(
                                              request.current_status_id,
                                            )) ||
                                          (!!filteredJustificativeTypes.length &&
                                            !filteredJustificativeTypes.includes(
                                              request.justificative_type_id,
                                            )) ||
                                          !!hideToUser(
                                            moment(request.datetime_from, "DD/MM/YYYY HH:mm"),
                                            moment(request.datetime_to, "DD/MM/YYYY HH:mm"),
                                          )
                                        )
                                          return (
                                            <Col
                                              span={1}
                                              key={c}
                                              style={{ borderRight: "1px solid #bcbcbc" }}
                                            >
                                              {"\u00A0"}
                                            </Col>
                                          );
                                        return (
                                          <Col
                                            span={1}
                                            key={c}
                                            className="request-cell"
                                            style={{
                                              border: "1px solid black !important",
                                              borderTopLeftRadius: isStart ? "7px" : "",
                                              borderBottomLeftRadius: isStart ? "7px" : "",
                                              borderTopRightRadius: isEnd ? "7px" : "",
                                              borderBottomRightRadius: isEnd ? "7px" : "",
                                              borderRight: isEnd
                                                ? "1px solid #bcbcbc!important"
                                                : "",
                                              backgroundColor:
                                                //@ts-ignore
                                                request?.color,
                                              /*backgroundImage:
                                                "linear-gradient(45deg, rgb(" +
                                                //@ts-ignore
                                                hexToRgb(request?.color) +
                                                ", 0.9) 50%,rgb(" +
                                                //@ts-ignore
                                                hexToRgb(request?.color) +
                                                ", 0.85)50%)",*/
                                            }}
                                            onClick={() => handleOpenModal(request.id)}
                                          >
                                            <UIText
                                              color={"white"}
                                              style={{
                                                alignSelf: "center",
                                                fontSize: "13px",
                                              }}
                                            >
                                              {moment(datesRange[c]).format("dd DD")}
                                              {isEnd && request.current_status_name == "Accettata" && (
                                                <span
                                                  style={{
                                                    position: "absolute",
                                                    top: "-6px",
                                                    right: "-2px",
                                                    zIndex: 999,
                                                  }}
                                                >
                                                  <CheckCircleTwoTone twoToneColor={"#88d660"} />
                                                </span>
                                              )}
                                              {isEnd && request.current_status_name == "Validata" && (
                                                <span
                                                  style={{
                                                    position: "absolute",
                                                    top: "-6px",
                                                    right: "-2px",
                                                    zIndex: 999,
                                                  }}
                                                >
                                                  <ExclamationCircleTwoTone
                                                    twoToneColor={"#d6d060"}
                                                  />
                                                </span>
                                              )}
                                              {isEnd && request.current_status_name == "In Attesa" && (
                                                <span
                                                  style={{
                                                    position: "absolute",
                                                    top: "-6px",
                                                    right: "-2px",
                                                    zIndex: 999,
                                                  }}
                                                >
                                                  <ClockCircleTwoTone twoToneColor={"#c8c8c8"} />
                                                </span>
                                              )}
                                            </UIText>
                                          </Col>
                                        );
                                      })}
                                    </Row>
                                  </Col>
                                </Row>
                              ))}
                          </CustomPanel>
                        );
                    })}
                </StyledCollapse>
              ) : (
                <Row>
                  <Col
                    style={{
                      border: "1px solid #d9d9d9",
                      borderRadius: "7px",
                      padding: "50px",
                      textAlign: "center",
                    }}
                    span={24}
                  >
                    <UITitle level={4}>Nessuna richiesta disponibile</UITitle>
                  </Col>
                </Row>
              )}
            </StyledSpan>
          </Row>
        </Col>
      </Row>
      {!!activeRequest && (
        <RequestDetailDrawer
          visible={showModal}
          modalLoading={modalLoading}
          onClose={handleCloseModal}
          request={activeRequest}
          request_id={activeRequest.id}
          currentRange={datesRange}
        />
      )}
    </UITransition>
  );
};

const StyledSpan = styled.span`
  overflow: scroll;
  width: 100%;
  padding-bottom: 15px;
  ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar {
    height: 10px;
  }
  .calenda {
    min-width: 1105px;
  }
  .giorni {
    min-width: 1105px;
    margin-bottom: 10px;
  }
  .giorni .ant-cascader .ant-select-selector {
    border-radius: 7px;
  }
`;

// mettere in file singolo
const StyledCollapse = styled(Collapse)`
  border-radius: 7px;

  .ant-collapse-header {
    display: block !important;
  }
  .ant-collapse:first-child {
    border-radius: 7px;
  }
  .ant-collapse-item:last-of-type {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
  }

  .ant-collapse-item:last-of-type [data-side-left]:not([data-user]) {
    border-bottom-left-radius: 7px;
  }

  .ant-collapse-item:first-of-type {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
  }
  .ant-collapse-item:first-of-type [data-side-right]:not([data-user]) [data-days-cells] {
    border-top: 1px solid #bcbcbc;
    border-top-right-radius: 7px;
  }

  .ant-collapse-item:first-of-type
    [data-side-right]:not([data-user])
    [data-days-cells]
    .ant-col:last-of-type {
    border-top-right-radius: 7px;
  }

  .ant-collapse-item:last-of-type {
    border-bottom-right-radius: 7px;
  }
  .ant-collapse .ant-col-1:last-of-type {
    border-bottom-right-radius: 7px;
  }
  .ant-collapse-item:first-of-type [data-side-left]:not([data-user]) {
    border-top-left-radius: 7px;
  }
  .ant-collapse-item:last-of-type .ant-row:last-child [data-side-left][data-user] {
    border-bottom-left-radius: 7px;
  }

  .ant-collapse-header[aria-expanded="true"] [data-side-left] {
    border-bottom-left-radius: 0px !important;
  }
`;
// mettere in file singolo
const CustomPanel = styled(Panel)`
  .ant-collapse-header {
    padding: 0px !important;
  }

  .ant-row[data-days-cells] {
    height: 100% !important;
  }
  .ant-collapse-content-box {
    padding: 0px !important;
  }
  .ant-collapse-arrow:not(.buono) {
    display: none !important;
  }

  [data-side-left] {
    padding: 12px 10px;
    background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.25"}) !important;
  }
  [data-side-left][data-user] {
    background-color: rgb(${hexToRgb("" + BaseColor) + ", 0.20"}) !important;
  }
  [data-side-right]:not([data-user]) {
    //pointer-events: none !important;
    cursor: default;
    touch-action: none;
  }
  [data-days-cells] {
    box-shadow: inset 7px 0 9px -7px rgb(124 124 124 / 40%),
      inset -7px 0 9px -7px rgb(124 124 124 / 40%);
  }
  .ant-col .request-cell {
    border: 0px !important;
    border-right: 1px solid #bcbcbc;

    background-size: 10px 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    font-weight: bold;
    text-transform: capitalize;

    height: 70%;
    margin-top: 7px;
  }
  .request-cell + .ant-col:not(.request-cell) {
    //border-left: 1px solid #bcbcbc;
    box-shadow: -1px 0px 0px 0px #bcbcbc;
  }
`;

// mettere in file singolo
const CustomDatePicker = styled(DatePicker)`
  border-radius: 7px;
  margin: 0px 15px;
  input {
    text-transform: capitalize;
  }
`;
