/** @format */

import React, { useEffect, useState } from "react";
import { DocumentsTabEdit } from "./partials/DocumentsTabEdit";
import { PersonalInfoTabEdit } from "./partials/PersonalInfoTabEdit";
import { ProfessionalInfoTabEdit } from "./partials/ProfessionalInfoTabEdit";
import { Skeleton, Col, Form, Row, Tabs, Breadcrumb } from "antd";
import { UIButton } from "../../ui/Button";
import { useHistory, useParams } from "react-router";
import { Routes } from "../../../utils/Routes";
import { UICard } from "../../ui/Card";
import { API_ENDPOINTS } from "../../../constants/api";
import apiClient from "../../../utils/apiClient";
import { utilityFormatFindList } from "../../../utils/format/apiUser";
import { useDispatch, useSelector } from "react-redux";
import {
  actionUserDeleteRequest,
  actionUserUpdateRequest,
} from "../../../redux-modules/actions/actionsUsers";
import {
  selectorGroupsListLoading,
  selectorUserAvatarUpdated,
  selectorUserDeleted,
  selectorUserDocumentUpdated,
  selectorUserUpdated,
} from "../../../redux-modules/selectors/selectorsUI";
import { TUserComplete } from "../../../redux-modules/declarations/maps/auth";
import { UITitle } from "../../ui/Title";
import { BaseColor } from "../../../theme/maps/Colors";
import { NewPersonalDocumentDrawer } from "./partials/NewDocumentDrawer";
import { DeleteCard } from "../../layout/DeleteCard";
import { TActionUserDeleteRequest } from "../../../redux-modules/declarations/actions/users";
import { UILink } from "../../ui/Link";
import { TDocumentCreateRequest } from "../../../redux-modules/declarations/maps/documents";
import { actionDocumentCreateRequest } from "../../../redux-modules/actions/actionsDocument";
import RoutedTabs from "../../ui/RoutedTabs";
import { USERTABS } from "../../../constants/settings";
import { TabsProps } from "antd/lib/tabs";
import { hasPermission } from "../../../utils/permissions/hasPermission";
import { selectorPermissionList } from "../../../redux-modules/selectors/selectorsPermissions";
import { selectorLoggedUser } from "../../../redux-modules/selectors/selectorsAuth";
import { DetailsTabEdit } from "./partials/UserDetails";
import { TModel } from "../../../constants/maps/BackendEnums";
import { TModelDetail } from "../../../redux-modules/declarations/maps/modelDetails";
import { TStore } from "../../../redux-modules/declarations/store";
import { selectorModelDetailsByModel } from "../../../redux-modules/selectors/selectorsModelDetails";
import { showNotification } from "../../../utils/showNotification";
import { UserAssignmentTab } from "./partials/UserAssignmentTab";
import CommunicationTab from "./partials/CommunicationTab";
import { UIInputPassword } from "../../ui/Input/InputPassword";

const { TabPane } = Tabs;

export const ViewUserEdit = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isUserUpdated = useSelector(selectorUserUpdated);
  const isUserDeleted = useSelector(selectorUserDeleted);
  const isUserAvatarUpdated = useSelector(selectorUserAvatarUpdated);
  const isDocumentUploaded = useSelector(selectorUserDocumentUpdated);

  //@ts-ignore
  const { id_user } = useParams();

  const [showResetPasswordForm, setshowResetPasswordForm] = useState(false);
  const [userInfo, setUserInfo] = useState<TUserComplete>();
  const [formValues, setFormValues] = useState<TUserComplete>();
  const [roleList, setRoleList] = useState<[]>([]);
  const [groupList, setGroupList] = useState<[]>([]);
  const [locationList, setLocationList] = useState<[]>([]);
  const [newDocumentVisible, setNewDocumentVisible] = useState(false);
  const [routeTabsConfig, setRouteTabsConfig] = useState({});
  const [form] = Form.useForm();
  const permissionList = useSelector(selectorPermissionList);
  const details: TModelDetail[] = useSelector((store: TStore) =>
    selectorModelDetailsByModel(store, TModel.User, true),
  );
  const loggedUser = useSelector(selectorLoggedUser);
  const isAdmin = loggedUser?.role === "ROOT";

  const canShowDocumentTab = hasPermission(permissionList, "PersonalDocument", "Show");
  const canShowDotazioniTab = hasPermission(permissionList, "Product", "Personalproducts");
  const canManageForfettizzato = hasPermission(permissionList, "Presence", "Canmanageforfettizzato");
  const canShowCommunicationTab = hasPermission(
    permissionList,
    "Communication",
    "Personalcommunications",
  );
  const canDeleteUser = hasPermission(permissionList, "User", "Delete");
  const isGroupLoading = useSelector(selectorGroupsListLoading);

  const removeUser = (): TActionUserDeleteRequest => dispatch(actionUserDeleteRequest(id_user));

  const disableUser = (): void => {
    apiClient
      .post(API_ENDPOINTS.USER_DISABLE, { user_id: id_user })
      .then(res => {
        if (res.data.success) {
          showNotification(
            "success",
            "Utente disabilitato",
            "L'utente è stato disabilitato con successo",
          );
          history.push(Routes.users);
        }
      })
      .catch(err => {
        showNotification("error", "Errore", err.response.data.message);
      });
  };

  const enableUser = (): void => {
    apiClient.post(API_ENDPOINTS.USER_ENABLE, { user_id: id_user }).then(res => {
      if (res.data.success) {
        showNotification("success", "Utente abilitato", "L'utente è stato abilitato con successo");
        history.push(Routes.users);
      }
    });
  };

  const changePassword = (parameters: {}): void => {
    apiClient
      .post(API_ENDPOINTS.CHANGE_PASSWORD, parameters)
      .then(response => {
        showNotification("success", "Cambio password", response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch(error => {
        showNotification(
          "error",
          "Cambio password",
          "Errore nel cambio della password",
        );
      });
  };

  const showNewDocumentDrawer = (): void => setNewDocumentVisible(true);
  const onNewDocumentClose = (): void => setNewDocumentVisible(false);
  const handleNewDocumentSave = (info: TDocumentCreateRequest): void => {
    //dispatch save
    dispatch(actionDocumentCreateRequest(info));
  };

  const onChangeForm = (e: React.FormEvent<HTMLFormElement>): void => {
    //@ts-ignore
    setFormValues({ ...formValues, ...e });
  };

  //@ts-ignore
  const handleUpdate = (e): void => {
    e = formValues;
    let userObj = {
      id: id_user,
      fiscal_code: e.fiscal_code,
      name: e.name,
      surname: e.surname,
      email: e.email,
      phone: e.phone,
      phone2: e.phone2,
      mobile: e.mobile,
      mobile2: e.mobile2,
      password: e.password,
      resetPasswordTime: e.resetPasswordTime,
      address: e.address,
      city: e.city,
      cap: e.cap,
      state: e.state,
      office_id: e.office || userInfo?.office,
      department_id: e.department || userInfo?.department,
      groups: e.groups || userInfo?.groups,
      role_id: e.role,
      pinCode: e.pinCode,
      qrCode: e.qrCode,
      workEmail: e.workEmail,
      intNumberPhone: e.intNumberPhone,
      relationship: e.relationship,
      daily_hours: e.daily_hours,
      contract_weekly_hours: e.contract_weekly_hours,
      contract_weekly_days: e.contract_weekly_days,
      is_single_badge: e.is_single_badge,
      is_presence_in_app: e.is_presence_in_app,
      is_nfc_enabled: e.is_nfc_enabled,
      is_pausapranzo_enabled: e.is_pausapranzo_enabled,
      pausapranzo_hours: e.pausapranzo_hours,
      details: [],
      is_forfettizzato: e.is_forfettizzato ?? 0,
    };

    details.forEach(detail => {
      const detailValue = form.getFieldValue("detail?" + detail.id);

      if (detailValue) {
        //@ts-ignore
        userObj["details"].push({
          //@ts-ignore
          model_detail_id: detail.id,
          //@ts-ignore
          value: detailValue,
        });
      }
    });

    //dispatch
    dispatch(actionUserUpdateRequest(userObj));
  };

  useEffect(() => {
    if (isUserUpdated || isUserDeleted) history.push(Routes.users);
  }, [isUserUpdated, isUserDeleted]);

  useEffect(() => {
    if (isDocumentUploaded) onNewDocumentClose();
  }, [isDocumentUploaded]);

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const fetchData = async () => {
    const response = await apiClient
      .get(API_ENDPOINTS.USERS_LIST + "/" + id_user)
      .then(response => {
        //@ts-ignore
        setUserInfo(utilityFormatFindList(response.data.data));
        form.setFieldsValue(utilityFormatFindList(response.data.data));
      })
      .catch(e => {
        history.push(Routes.home);
        showNotification(
          "warning",
          "Oops..",
          "Sembra che tu non abbia i permessi per visualizzare la pagina",
        );
      });
  };

  //@ts-ignore
  useEffect(() => {
    setUserInfo(undefined);
    apiClient.get(API_ENDPOINTS.ROLES).then(res => {
      let list = res.data.data;
      setRoleList(list);
    });
    apiClient.get(API_ENDPOINTS.GROUPS).then(res => {
      setGroupList(res.data.data);
    });
    apiClient.get(API_ENDPOINTS.LOCATIONS).then(res => {
      setLocationList(res.data.data);
    });

    fetchData();
  }, [isUserAvatarUpdated, isDocumentUploaded, id_user]);

  useEffect(() => {
    if (userInfo && !!groupList && !!roleList && !!locationList) {
      setFormValues(userInfo);
      setRouteTabsConfig({
        comunicazioni: canShowCommunicationTab
          ? {
              label: "Comunicazioni",
              component: function comunicazioni() {
                setshowResetPasswordForm(false);
                return (
                  //@ts-ignore
                  <CommunicationTab
                    key={"comunicazioni"}
                    user_id={id_user}
                    communications={userInfo?.communications}
                  />
                );
              },
              getRoute: (url: string): string => `${url}${USERTABS.COMMUNICATIONS}`,
            }
          : undefined,
        dotazioni: canShowDotazioniTab
          ? {
              label: "Assets Aziendali",
              component: function dotazioni() {
                setshowResetPasswordForm(false);
                return (
                  <UserAssignmentTab
                    key="assegnazioni"
                    updateData={fetchData}
                    //@ts-ignore
                    userAssignments={userInfo?.warehouses_products}
                    user_id={id_user}
                  />
                );
              },
              getRoute: (url: string): string => `${url}${USERTABS.ASSIGNMENTS}`,
            }
          : undefined,
        documents: canShowDocumentTab
          ? {
              label: "Documenti",
              component: function documents() {
                setshowResetPasswordForm(false);
                return (
                  <DocumentsTabEdit
                    key="documenti"
                    onAddDocument={showNewDocumentDrawer}
                    documents={userInfo?.documents}
                    user_id={id_user}
                    canCreate={userInfo.can_be_edited}
                  />
                );
              },
              getRoute: (url: string): string => `${url}${USERTABS.DOCUMENTS}`,
            }
          : undefined,
        "informazioni-aggiuntive":
          details.length > 0
            ? {
                label: "Informazioni aggiuntive",
                component: function informazioniAggiuntive() {
                  setshowResetPasswordForm(false);
                  return <DetailsTabEdit key="aggiuntive" />;
                },
                getRoute: (url: string): string => `${url}${USERTABS.INFORMAZIONI_AGGIUNTIVE}`,
              }
            : undefined,
        "informazioni-professionali": {
          label: "Informazioni Professionali",
          component: function professional() {
            setshowResetPasswordForm(false);
            return (
              <ProfessionalInfoTabEdit
                key="base"
                groups={groupList}
                groupsLoading={isGroupLoading}
                locations={locationList}
                is_pausapranzo_enabled={!!formValues?.is_pausapranzo_enabled}
                canManageForfettizzato={canManageForfettizzato}
              />
            );
          },
          getRoute: (url: string): string => `${url}${USERTABS.PROFESIONAL_INFO}`,
        },
        "/": {
          label: "Informazioni Personali",
          component: function personal() {
            setshowResetPasswordForm(true);
            return <PersonalInfoTabEdit roles={roleList} avatar={userInfo?.avatar} />;
          },
          getRoute: (url: string): string => url,
        },
      });
    }
  }, [userInfo, groupList, locationList, roleList, id_user]);

  const tabsProps: TabsProps = {
    tabPosition: "top",
    destroyInactiveTabPane: false,
  };

  return (
    <>
      <Row>
        <Col>
          <Row>
            <Col>
              <UITitle level={2} color={BaseColor}>
                Dipendenti
              </UITitle>
            </Col>
          </Row>
          <Row>
            <Breadcrumb>
              <Breadcrumb.Item>
                <UILink route={Routes.users} label={"Dipendenti"} />
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {userInfo ? userInfo.name + " " + userInfo.surname : ""}
              </Breadcrumb.Item>
            </Breadcrumb>
          </Row>
        </Col>
      </Row>
      <Row style={{ marginTop: "15px" }}>
        <Col xs={24}>
          {!userInfo ||
          !locationList ||
          !groupList ||
          !roleList ||
          typeof routeTabsConfig === "undefined" ? (
            <UICard style={{ marginBottom: "20px" }}>
              <Skeleton active />
            </UICard>
          ) : (
            <Form
              name="update-user"
              className="update-user"
              data-cy="update-user-form"
              onFinish={handleUpdate}
              form={form}
              initialValues={userInfo}
              layout="vertical"
              onValuesChange={onChangeForm}
              disabled={!userInfo.can_be_edited}
            >
              <UICard style={{ marginBottom: "20px" }}>
                <RoutedTabs
                  tabsProps={tabsProps}
                  //@ts-ignore
                  routeConfig={routeTabsConfig}
                  basePath={"/utenti/" + userInfo?.id}
                />

                <Row justify="space-between" align="bottom" gutter={[12, 12]}>
                  <Col>
                    <UILink route={Routes.users} label={<UIButton>Annulla</UIButton>} />
                  </Col>
                  <Col>
                    <Form.Item noStyle>
                      <UIButton type="primary" data-cy="update-user-button" htmlType="submit">
                        Salva Modifiche
                      </UIButton>
                    </Form.Item>
                  </Col>
                </Row>
              </UICard>
              <NewPersonalDocumentDrawer
                onClose={onNewDocumentClose}
                visible={newDocumentVisible}
                onSave={handleNewDocumentSave}
              />
            </Form>
          )}
        </Col>
      </Row>
      {showResetPasswordForm && (
        <Row style={{ marginBottom: "20px" }}>
          <Col xs={24}>
            <UICard>
              <Row>
                <Col>
                  <UITitle level={5}>Cambia Password</UITitle>
                </Col>
              </Row>
              <Form
                layout="vertical"
                name="basic"
                onFinish={changePassword}
                onFinishFailed={(e): void => console.log(e)}
              >
                <Row>
                  <Col xs={24}>
                    <Row gutter={[24, 0]}>
                      <Col md={8}>
                        <Form.Item
                          name="old_password"
                          label={"Vecchia Password"}
                          rules={[{ required: true, message: "Inserisci la tua password." }]}
                        >
                          <UIInputPassword placeholder="Password" />
                        </Form.Item>
                      </Col>
                      <Col md={8}>
                        <Form.Item
                          name="password"
                          label={"Nuova Password"}
                          rules={[{ required: true, message: "Inserisci una password valida!" }]}
                        >
                          <UIInputPassword placeholder="Password" />
                        </Form.Item>
                      </Col>
                      <Col md={8}>
                        <Form.Item
                          name="confirm"
                          label="Conferma Password"
                          dependencies={["password"]}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: "Per favore conferma la password!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue("password") === value) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error("Le passwords che hai inserito non corrispondono!"),
                                );
                              },
                            }),
                          ]}
                        >
                          <UIInputPassword placeholder="Conferma Password" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row justify="space-between" align="bottom" gutter={[12, 12]}>
                  <Col></Col>
                  <Col>
                    <Form.Item noStyle>
                      <UIButton type="primary" htmlType="submit">
                        Conferma Password
                      </UIButton>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </UICard>
          </Col>
        </Row>
      )}
      {canDeleteUser && userInfo?.is_disabled && (
        <Row style={{ marginBottom: "20px" }}>
          <Col xs={24}>
            <DeleteCard
              actionToDispatch={enableUser}
              data-cy="enable-user-card"
              title="Abilita Dipendente"
              text="Cliccando sul bottone abilita, abiliterai nuovamente il dipendente selezionato. Assicurati di voler abilitare l'utente."
              removeButtonLabel="Abilita Dipendente"
              enable={true}
            />
          </Col>
        </Row>
      )}
      {canDeleteUser && !userInfo?.is_disabled && (
        <Row style={{ marginBottom: "20px" }}>
          <Col xs={24}>
            <DeleteCard
              actionToDispatch={disableUser}
              data-cy="disable-user-card"
              title="Disabilita Dipendente"
              text="Cliccando sul bottone disabilita, bloccherai temporaneamente il dipendente selezionato. Assicurati di voler disabilitare l'utente."
              removeButtonLabel="Disabilita Dipendente"
              disable={true}
            />
          </Col>
        </Row>
      )}
      {canDeleteUser && (
        <Row style={{ marginBottom: "30px" }}>
          <Col xs={24}>
            <DeleteCard
              actionToDispatch={removeUser}
              data-cy="remove-user-card"
              title="Elimina Dipendente"
              text="Cliccando sul bottone elimina eliminerai definitivamente il dipendente selezionato. Assicurati di voler rimuovere l'utente."
              removeButtonLabel="Elimina Dipendente"
            />
          </Col>
        </Row>
      )}
    </>
  );
};
