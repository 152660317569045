/** @format */

import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Col, Divider, Drawer, Row, Skeleton, Tag } from "antd";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_ENDPOINTS } from "../../../../constants/api";
import {
  actionPresenceDeleteSuccess,
  actionPresenceUpdateSuccess,
} from "../../../../redux-modules/actions/actionsPresences";
import { TUser } from "../../../../redux-modules/declarations/maps/auth";
import { TCustomPresence } from "../../../../redux-modules/declarations/maps/presences";
import { selectorPermissionList } from "../../../../redux-modules/selectors/selectorsPermissions";
import { selectorPresenceTypeList } from "../../../../redux-modules/selectors/selectorsPresenceTypes";
import { selectorShiftTypeListWorkable } from "../../../../redux-modules/selectors/selectorsShiftTypes";
import { BaseColor } from "../../../../theme/maps/Colors";
import apiClient from "../../../../utils/apiClient";
import { hasPermission } from "../../../../utils/permissions/hasPermission";
import { showNotification } from "../../../../utils/showNotification";
import { darkenFromHex, stringToHex } from "../../../../utils/strings/stringToColor";
import { UIButton } from "../../../ui/Button";
import { UICard } from "../../../ui/Card";
import { UIDatePicker } from "../../../ui/DatePicker";
import { UISelect } from "../../../ui/Select";
import { UIText } from "../../../ui/Text";
import { UITitle } from "../../../ui/Title";
import { PresenceTypeIDS } from "../../../../constants/maps/BackendEnums";
const { Option } = UISelect;

interface Props {
  visible: boolean;
  onClose: (refresh?: boolean) => void;
  presence?: TCustomPresence;
  modalLoading?: boolean;
  updateAbsenceList: () => void;
  updateRequestList: () => void;
  currentDate: moment.Moment;
}

export const EditPresenceDrawer: FC<Props> = ({
  currentDate,
  visible,
  onClose,
  presence,
  modalLoading,
  updateAbsenceList,
  updateRequestList
}: Props): JSX.Element => {
  //@ts-ignore
  const dispatch = useDispatch();
  const [user, setUser] = useState<TUser>();
  const presenceTypes = useSelector(selectorPresenceTypeList);
  const permissionList = useSelector(selectorPermissionList);
  const canDeletePresence = hasPermission(permissionList, "Presence", "Delete");
  const canShowBancaore = hasPermission(permissionList, "Presence", "Canshowbancaore");
  const canShowStraordinario = hasPermission(permissionList, "Presence", "Canshowstraordinario");
  const canManageForfettizzato = hasPermission(permissionList, "Presence", "Canmanageforfettizzato");
  const canChangeTime = hasPermission(permissionList, "Presence", "Canchangetime");
  const shiftTypeList = useSelector(selectorShiftTypeListWorkable);
  const [editingShift, setEditingShift] = useState(false);
  const [editingStart, setEditingStart] = useState(false);
  const [editingStop, setEditingStop] = useState(false);

  const getDuration = (presence: TCustomPresence) => {
    let duration = moment.duration(
      moment(presence.original.datetime_to, "DD/MM/YYYY HH:mm:ss").diff(
        moment(presence.original.datetime_from, "DD/MM/YYYY HH:mm:ss"),
      ),
    );
    if (!!presence.modified) {
      duration = moment.duration(
        moment(presence.modified.presence_datetime_to, "DD/MM/YYYY HH:mm:ss").diff(
          moment(presence.modified.presence_datetime_from, "DD/MM/YYYY HH:mm:ss"),
        ),
      );
    }
    return duration.asHours();
  };

  useEffect(() => {
    if (presence) {
      setUser(presence.original.user);
    }
  }, [presence]);

  const handleClose = () => {
    if (onClose)
      // quello che vogliamo
      onClose();
  };

  const onRemove = () => {
    if (presence) {
      apiClient
        .delete(API_ENDPOINTS.PRESENCES + "/" + presence.original.id)
        .then(() => {
          showNotification("success", "Presenza rimossa", "Presenza rimossa con successo!");
          dispatch(actionPresenceDeleteSuccess(presence.original.id));
          updateAbsenceList();
          if (onClose) onClose();
        })
        .catch(e => {
          console.log(e.response.data.message);
          showNotification("error", "Errore", e.response.data.message);
        });
    }
  };

  //@ts-ignore
  const changePlanning = value => {
    apiClient
      .post(API_ENDPOINTS.PRESENCES + "_planning_switch", {
        presence_id: presence?.original.id,
        shift_type_id: value,
        current_date: currentDate.format("YYYY-MM-DD"),
      })
      .then(res => {
        showNotification("success", "Turno sostituito", "Turno sostituito con successo!");
        let refresh = true;
        /*
        if (
          moment(res.data.data.original.datetime_to, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") ==
          moment(res.data.data.original.datetime_from, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")
        ) {
          dispatch(actionPresenceUpdateSuccess(res.data.data));
          updateAbsenceList();
        } else {
          refresh = true;
        }*/
        if (onClose) onClose(refresh);
      })
      .catch(err => {
        showNotification("error", "Errore", err.response.data.message);
      });
    setEditingShift(false);
  };

  //@ts-ignore
  const changeShiftType = value => {
    apiClient
      .post(API_ENDPOINTS.PRESENCES + "_planning_assign", {
        presence_id: presence?.original.id,
        shift_type_id: value,
        current_date: currentDate.format("YYYY-MM-DD"),
      })
      .then(res => {
        showNotification("success", "Turno assegnato", "Turno assegnato con successo!");
        let refresh = true;
        /*
        if (
          moment(res.data.data.original.datetime_to, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") ==
          moment(res.data.data.original.datetime_from, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")
        ) {
          dispatch(actionPresenceUpdateSuccess(res.data.data));
          updateAbsenceList();
        } else {
          refresh = true;
        }*/
        if (onClose) onClose(refresh);
      })
      .catch(err => {
        showNotification("error", "Errore", err.response.data.message);
      });
    setEditingShift(false);
  };

  const removePlanning = (shift_planning_id: string) => {
    apiClient
      .post(API_ENDPOINTS.PRESENCES + "_planning_remove", {
        presence_id: presence?.original.id,
        shift_planning_id: shift_planning_id,
      })
      .then(res => {
        showNotification("success", "Turno rimosso", "Turno rimosso con successo!");
        let refresh = false;
        if (
          moment(res.data.data.original.datetime_to, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") ==
          moment(res.data.data.original.datetime_from, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")
        ) {
          dispatch(actionPresenceUpdateSuccess(res.data.data));
          updateAbsenceList();
        } else {
          refresh = true;
        }
        if (onClose) onClose(refresh);
      })
      .catch(err => {
        showNotification("error", "Errore", err.response.data.message);
      });
    //dispatch(actionShiftDeleteRequest(shift_planning_id));
  };

  const timebankApprove = (is_straordinario: boolean, negative_straordinario: boolean, conferma_turno_parziale: boolean = false, is_rol: boolean = false, conferma_straordinario_turno_associato: boolean = false) => {
    apiClient
      .post(API_ENDPOINTS.PRESENCES + "_timebank_approve", {
        presence_id: presence?.original.id,
        is_straordinario: is_straordinario,
        negative_straordinario: negative_straordinario,
        conferma_turno_parziale: conferma_turno_parziale,
        conferma_straordinario_turno_associato: conferma_straordinario_turno_associato
      })
      .then(res => {
        if(is_rol){
          updateRequestList();
          updateAbsenceList();
        }
        let title = is_straordinario ? "Gestione Turno" : "Banca ore approvata";
        let msg = is_straordinario
          ? negative_straordinario
            ? "Turno parziale confermato."
            : "Straordinario approvato."
          : "Banca ore approvata con successo!";
        showNotification("success", title, msg);
        let refresh = true;
        /*
        if (
          moment(res.data.data.original.datetime_to, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") ==
          moment(res.data.data.original.datetime_from, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")
        ) {
          dispatch(actionPresenceUpdateSuccess(res.data.data));
          updateAbsenceList();
        } else {
          refresh = true;
        }*/
        if (onClose) onClose(refresh);
      })
      .catch(err => {
        showNotification("error", "Errore", err.response.data.message);
      });
    //dispatch(actionShiftDeleteRequest(shift_planning_id));
  };

  const timebankUnapprove = (is_straordinario: boolean) => {
    apiClient
      .get(API_ENDPOINTS.TIMEBANK_DINIEGO + "/" + presence?.modified.id + "?is_straordinario="+(is_straordinario ? 1 : 0))
      .then(res => {
        let title = is_straordinario ? "Gestione Turno" : "Banca ore rifiutata";
        let msg = is_straordinario
          ? "Straordinario rifiutato con successo."
          : "Banca ore rifiutata con successo!";
        showNotification("success", title, msg);
        /*let refresh = false;
        if (
          moment(res.data.data.original.datetime_to, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") ==
          moment(res.data.data.original.datetime_from, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")
        ) {
          dispatch(actionPresenceUpdateSuccess(res.data.data));
          updateAbsenceList();
        } else {
          refresh = true;
        }*/
        if (onClose) onClose(true);
      })
      .catch(err => {
        showNotification("error", "Errore", err.response.data.messagee);
      });
    //dispatch(actionShiftDeleteRequest(shift_planning_id));
  };

  const updatePresenceTimes = ({ start, stop }: { start: moment.Moment; stop: moment.Moment }) => {
    apiClient
      .post(API_ENDPOINTS.PRESENCES + "_timebank_update", {
        presence_id: presence?.original.id,
        start: start.format("YYYY-MM-DD HH:mm:ss"),
        stop: stop.format("YYYY-MM-DD HH:mm:ss"),
        current_date: currentDate.format("YYYY-MM-DD"),
      })
      .then(res => {
        showNotification("success", "Orari modificati", "Orari modificati con successo!");
        let refresh = true;
        /*
        if (
          moment(res.data.data.original.datetime_to, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") ==
          moment(res.data.data.original.datetime_from, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD")
        ) {
          dispatch(actionPresenceUpdateSuccess(res.data.data));
          updateAbsenceList();
        } else {
          refresh = true;
        }*/

        if (onClose) onClose(refresh);
      })
      .catch(err => {
        showNotification(
          "error",
          "Errore", //@ts-ignore
          err.response.data.errors
            ? //@ts-ignore
              Object.values(err.response.data.errors).join(`\n`)
            : //@ts-ignore
              err.response.data.message,
        );
      });
    //dispatch(actionShiftDeleteRequest(shift_planning_id));
  };

  if (!user || !presenceTypes)
    return (
      <Drawer
        title="Modifica Presenza"
        placement="right"
        onClose={handleClose}
        closable={true}
        visible={visible}
        width={400}
      >
        <UICard style={{ marginBottom: "20px", border: "none" }}>
          <Skeleton active />
        </UICard>
      </Drawer>
    );

  return (
    <Drawer
      title="Modifica Presenza"
      placement="right"
      onClose={handleClose}
      closable={true}
      visible={visible}
      width={400}
      footer={
        <Row justify="space-between">
          <Col>
            {canDeletePresence && (
              <UIButton
                onClick={onRemove}
                style={{
                  borderRadius: "7px",
                  backgroundColor: "#e00102",
                  borderColor: "#e00102",
                  color: "white",
                }}
              >
                Elimina
              </UIButton>
            )}
          </Col>
          <Col>
            <UIButton onClick={() => onClose()}>Annulla</UIButton>
          </Col>
        </Row>
      }
    >
      <Row>
        <Col span={24}>
          {!presence ? (
            <UICard style={{ marginBottom: "20px", border: "none" }}>
              <Skeleton active />
            </UICard>
          ) : (
            <>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <Avatar
                    size={64}
                    //@ts-ignore
                    src={presence?.original.user.avatar}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  <UITitle level={3} color={BaseColor}>
                    {presence.original.user.name + " " + presence.original.user.surname}
                  </UITitle>
                </Col>
              </Row>
              <Row>
                <Col span={24} style={{ textAlign: "center" }}>
                  {presence.original.user.groups.map((group, index) => (
                    <Tag
                      key={index}
                      color={darkenFromHex(stringToHex(group.name))}
                      style={{ borderRadius: "5px" }}
                    >
                      {group.name}
                    </Tag>
                  ))}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>
              {getDuration(presence) > 13 && (
                <>
                  <Row>
                    <Col span={24}>
                      <UITitle level={4} color={BaseColor}>
                        Il turno supera le 13 ore, possibile mancata chiusura turno!
                      </UITitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Divider />
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col span={24}>
                  <UITitle level={5} color={BaseColor}>
                    Info
                  </UITitle>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Row style={{ marginBottom: "5px" }}>
                    <Col span={24}>
                      <UIText>Tipologia Turno:</UIText>{" "}
                      <UIText style={{ fontWeight: "bold" }}>
                        <Tag
                          color={presence.original.presence_type_color}
                          style={{
                            borderRadius: "5px",
                          }}
                        >
                          {presence.original.presence_type_name}
                        </Tag>
                      </UIText>
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "5px" }}>
                    <Col span={24} style={{ display: "inline-flex", alignItems: "center" }}>
                      <UIText style={{ marginRight: "5px" }}>Dalle:</UIText>{" "}
                      {editingStart ? (
                        <>
                          <UIDatePicker
                            format="DD/MM/YYYY HH:mm"
                            //@ts-ignore
                            showTime
                            //@ts-ignore
                            onChange={(date: moment.Moment) =>
                              updatePresenceTimes({
                                start: date,
                                stop: moment(
                                  presence.modified
                                    ? presence.modified.presence_datetime_to
                                    : presence.original.datetime_to,
                                  "DD/MM/YYYY HH:mm",
                                ),
                              })
                            }
                            value={
                              presence.modified
                                ? moment(
                                    presence.modified.presence_datetime_from,
                                    "DD/MM/YYYY HH:mm:ss",
                                  )
                                : moment(presence.original.datetime_from, "DD/MM/YYYY HH:mm:ss")
                            }
                          />
                        </>
                      ) : (
                        <>
                          <UIText style={{ fontWeight: "bold" }}>
                            {presence.modified
                              ? presence.modified.presence_datetime_from
                              : presence.original.datetime_from}
                          </UIText>
                          {canChangeTime && (
                            <span
                              onClick={() => setEditingStart(true)}
                              style={{
                                color: BaseColor,
                                cursor: "pointer",
                                margin: "0px 5px",
                                display: presence.original.user.is_single_badge
                                  ? "none"
                                  : "inline-flex",
                              }}
                            >
                              {(presence.modified == null || presence.modified.presence_type_id == PresenceTypeIDS.TURNO_ORDINARIO) && (<EditOutlined />)}
                            </span>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row style={{ marginBottom: "5px" }}>
                    <Col span={24} style={{ display: "inline-flex", alignItems: "center" }}>
                      <UIText style={{ marginRight: "5px" }}>Alle:</UIText>{" "}
                      {editingStop ? (
                        <>
                          <UIDatePicker
                            format="DD/MM/YYYY HH:mm"
                            //@ts-ignore
                            showTime
                            //@ts-ignore
                            onChange={(date: moment.Moment) =>
                              updatePresenceTimes({
                                start: moment(
                                  presence.modified
                                    ? presence.modified.presence_datetime_from
                                    : presence.original.datetime_from,
                                  "DD/MM/YYYY HH:mm",
                                ),
                                stop: date,
                              })
                            }
                            value={
                              presence.modified
                                ? moment(
                                    presence.modified.presence_datetime_to,
                                    "DD/MM/YYYY HH:mm:ss",
                                  )
                                : moment(presence.original.datetime_to, "DD/MM/YYYY HH:mm:ss")
                            }
                          />
                        </>
                      ) : (
                        <>
                          <UIText style={{ fontWeight: "bold" }}>
                            {presence.original.datetime_to
                              ? presence.modified
                                ? presence.modified.presence_datetime_to
                                : presence.original.datetime_to
                              : "In corso"}
                          </UIText>
                          {( canChangeTime || (presence.original != null && parseFloat(presence.original.centesimal_duration) >= 13) ) && (
                            <span
                              onClick={() => setEditingStop(true)}
                              style={{
                                color: BaseColor,
                                cursor: "pointer",
                                margin: "0px 5px",
                                display: presence.original.user.is_single_badge
                                  ? "none"
                                  : "inline-flex",
                              }}
                            >
                              {(presence.modified == null || presence.modified.presence_type_id == PresenceTypeIDS.TURNO_ORDINARIO) && (<EditOutlined />)}
                            </span>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                  {presence.original.datetime_to && (
                    <Row>
                      <Col span={24}>
                        <UIText>Durata:</UIText>{" "}
                        <UIText style={{ fontWeight: "bold" }}>
                          {" "}
                          {presence.modified
                            ? presence.modified.presence_duration
                            : presence.original.duration}
                        </UIText>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              { (presence.modified == null || presence.modified.presence_type_id == PresenceTypeIDS.TURNO_ORDINARIO) ? (<>
                {!!presence.modified && !!presence.modified.shift_planning_id ? (
                <>
                  <Row>
                    <Col span={24}>
                      <Divider />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <UITitle level={5} color={BaseColor}>
                        Turno associato
                      </UITitle>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      {editingShift ? (
                        <>
                          <Row justify="space-between">
                            <Col>
                              <UISelect
                                value={presence.modified.shift_type_id}
                                //@ts-ignore
                                onChange={changePlanning}
                                showSearch
                                filterOption={(input, option) =>
                                  ((option!.children as unknown) as string)
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                              >
                                {shiftTypeList.map((shiftType, index) => (
                                  <Option value={shiftType.id}>{shiftType.name}</Option>
                                ))}
                              </UISelect>
                            </Col>
                            <Col>
                              <span
                                style={{
                                  cursor: "pointer",
                                  fontSize: "16px",
                                  margin: "0px 10px",
                                  color: BaseColor,
                                }}
                                onClick={() => setEditingShift(false)}
                              >
                                <CloseOutlined />
                              </span>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <Row justify="space-between">
                          <Col>
                            <UIText strong>
                              {moment(presence.modified.shift_planning_datetime_from).format(
                                "HH:mm",
                              )}{" "}
                              -{" "}
                              {moment(presence.modified.shift_planning_datetime_to).format("HH:mm")}
                              {"     ("}
                              {presence.modified.shift_planning_centesimal_duration + ")"}
                            </UIText>
                          </Col>
                          <Col>
                            <span
                              style={{
                                cursor: "pointer",
                                fontSize: "16px",
                                margin: "0px 10px",
                                color: BaseColor,
                              }}
                              onClick={() => setEditingShift(true)}
                            >
                              <EditOutlined />
                            </span>
                            {/*
                          <span
                              style={{
                                cursor: "pointer",
                                fontSize: "16px",
                                margin: "0px 10px",
                                color: "red",
                              }}
                              onClick={() => removePlanning(presence.modified.shift_planning_id)}
                            >
                              <DeleteOutlined />
                            </span>
                              */}
                          </Col>
                        </Row>
                      )}
                    </Col>
                  </Row>
                  {presence.modified.presence_type_id == PresenceTypeIDS.TURNO_ORDINARIO && (canShowBancaore ||
                    (canShowStraordinario &&
                      parseInt(presence.modified.delta_second_duration) != 0)) && (
                    <>
                      <Row>
                        <Col span={24}>
                          <Divider />
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <UITitle level={5} color={BaseColor}>
                            {canShowBancaore
                              ? "Banca Ore"
                              : canShowStraordinario &&
                                parseInt(presence.modified.delta_second_duration) < 0
                              ? "Gestione Turno Anomalo"
                              : "Gestione Straodinario"}{" "}
                            <span
                              style={{
                                color:
                                  parseInt(presence.modified.is_timebank_approved) == 1
                                    ? "green"
                                    : "gray",
                              }}
                            >{`(${
                              parseInt(presence.modified.is_timebank_approved) == 1
                                ? "Approvata"
                                : parseInt(presence.modified.is_timebank_approved) == -1
                                ? "Rifiutata"
                                : "Non approvata"
                            })`}</span>
                          </UITitle>
                        </Col>
                      </Row>
                      {(canShowBancaore && canManageForfettizzato && presence.original.user.is_forfettizzato) ? 
                        <Row>
                          <Col span={24}>
                            <UIText>
                              <b>Utente con Straordinario Forfettizzato</b><br/><br/>
                            </UIText>
                          </Col>
                        </Row> : null
                      }
                      {parseInt(presence.modified.delta_ingresso_minuti) < 0 && (
                        <>
                          <Row>
                            <Col span={24}>
                              <UIText>
                                Minuti di ingresso in ritardo:{" "}
                                <span style={{ color: "red", fontWeight: 600 }}>
                                  {presence.modified.delta_ingresso_minuti}
                                </span>
                              </UIText>
                            </Col>
                          </Row>
                        </>
                      )}
                      {parseInt(presence.modified.delta_ingresso_minuti) > 0 && (
                        <>
                          <Row>
                            <Col span={24}>
                              <UIText>
                                Minuti di ingresso in anticipo:{" "}
                                <span style={{ color: "green", fontWeight: 600 }}>
                                  {presence.modified.delta_ingresso_minuti}
                                </span>
                              </UIText>
                            </Col>
                          </Row>
                        </>
                      )}
                      {parseInt(presence.modified.delta_uscita_minuti) < 0 && (
                        <>
                          <Row>
                            <Col span={24}>
                              <UIText>
                                Minuti di uscita in anticipo:{" "}
                                <span style={{ color: "red", fontWeight: 600 }}>
                                  {presence.modified.delta_uscita_minuti}
                                </span>
                              </UIText>
                            </Col>
                          </Row>
                        </>
                      )}
                      {parseInt(presence.modified.delta_uscita_minuti) > 0 && (
                        <>
                          <Row>
                            <Col span={24}>
                              <UIText>
                                Minuti di uscita in ritardo:{" "}
                                <span style={{ color: "green", fontWeight: 600 }}>
                                  {presence.modified.delta_uscita_minuti}
                                </span>
                              </UIText>
                            </Col>
                          </Row>
                        </>
                      )}
                      {canShowBancaore && (
                        <Row>
                         <Col span={24}>
                           <UIText>
                             Totale Differenza in minuti:{" "}
                             <span style={{ fontWeight: 600 }}>
                               {parseInt(presence.modified.delta_second_duration) / 60}
                             </span>
                             {"  (" + presence.modified.delta_centesimal_duration + ")"}
                           </UIText>
                         </Col>
                       </Row>
                      )}
                      {parseInt(presence.modified.is_timebank_approved) == 0 && (
                        <>
                          {canShowStraordinario &&
                          parseInt(presence.modified.delta_second_duration) < 0 ? (
                            <>
                            <Row style={{ marginTop: "10px" }} gutter={[8, 8]}>
                              <Col>
                                <UIButton
                                  onClick={() =>
                                    timebankApprove(
                                      true,
                                      true,
                                      false,
                                      true
                                    )
                                  }
                                >
                                  <CheckOutlined />{" "}
                                  {"Crea ROL equivalente"}
                                </UIButton>
                              </Col>
                            </Row>
                            <Row style={{ marginTop: "10px" }} gutter={[8, 8]}>
                              <Col>
                                <UIButton
                                  onClick={() =>
                                    timebankApprove(
                                      true,
                                      true,
                                      true
                                    )
                                  }
                                >
                                  <CloseOutlined />{" "}
                                  {"Conferma Turno Parziale"}
                                </UIButton>
                              </Col>
                            </Row>
                          </>
                          ) : (
                            <Row style={{ marginTop: "10px" }} gutter={[8, 8]}>
                              <Col>
                                <UIButton
                                  onClick={() =>
                                    timebankApprove(
                                      canShowStraordinario,
                                      parseInt(presence.modified.delta_second_duration) < 0
                                    )
                                  }
                                >
                                  <CheckOutlined />{" "}
                                  {canShowBancaore ? "Approva Banca Ore" : "Approva Straordinario"}
                                </UIButton>
                              </Col>
                              <Col>
                                <UIButton onClick={() => timebankUnapprove(canShowStraordinario)}>
                                  <CloseOutlined />{" "}
                                  {canShowBancaore ? "Rifiuta Banca Ore" : "Rifiuta Straordinario"}
                                </UIButton>
                              </Col>
                            </Row>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              ) : !!presence.original.datetime_to && !presence.original.user.is_single_badge ? (
                <>
                  <Row>
                    <Col span={24}>
                      <UIText>Nessun turno associato a questa timbratura</UIText>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <UIText>Per assegnare un turno, selezionalo tra i seguenti:</UIText>
                    </Col>
                  </Row>
                  <Row justify="space-between">
                    <Col span={24}>
                      <UISelect
                        //@ts-ignore
                        onChange={changeShiftType}
                        style={{ width: "100%" }}
                        showSearch
                        filterOption={(input, option): boolean =>
                          ((option!.children as unknown) as string)
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {shiftTypeList.map((shiftType, index) => (
                          <Option value={shiftType.id}>{shiftType.name}</Option>
                        ))}
                      </UISelect>
                    </Col>
                  </Row>
                </>
              ) : (
                <></>
              )}
              </>) : presence.modified.presence_type_id == PresenceTypeIDS.STRAORDINARIO && canShowBancaore ? 
              (<>
              
                <Row>
                  <Col span={24}>
                    <Divider />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <UITitle level={5} color={BaseColor}>
                      Turno associato
                    </UITitle>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col>
                    <UIText strong>
                      {moment(presence.modified.shift_planning_datetime_from).format(
                        "HH:mm",
                      )}{" "}
                      -{" "}
                      {moment(presence.modified.shift_planning_datetime_to).format("HH:mm")}
                      {"     ("}
                      {presence.modified.shift_planning_centesimal_duration + ")"}
                    </UIText>
                  </Col>
                </Row>

                <Row>
                  <Col span={24}>
                    <Divider />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <UITitle level={5} color={BaseColor}>
                      Straordinario {parseInt(presence.modified.is_timebank_approved) == 1 ? "riconosciuto" : ""} 
                    </UITitle>
                  </Col>
                </Row> 
                <Row justify="space-between">
                  <Col>
                    <UIText>
                      Ore di straordinario: {" "}
                      <span style={{ fontWeight: 600 }}>
                        { (parseFloat(presence.modified.shift_planning_centesimal_duration) + parseFloat(presence.modified.delta_centesimal_duration)).toFixed(2) }
                      </span>
                    </UIText>
                  </Col>
                </Row>
                <br></br>
                {parseInt(presence.modified.is_timebank_approved) == 0 ? 
                (
                  <>
                    <Col>
                      <Row>
                        <UIButton
                          onClick={() =>
                            timebankApprove(true, false)
                          }
                        >
                          {"Approva "} &nbsp; <b>{(parseFloat(presence.modified.shift_planning_centesimal_duration) + parseFloat(presence.modified.delta_centesimal_duration)).toFixed(2)}</b> &nbsp; {"timbrate"} 
                        </UIButton>
                      </Row>
                      <Row style={{marginTop: "10px"}}>
                        <UIButton
                          onClick={() =>
                            timebankApprove(true, false, false, false, true)
                          }
                        >
                          {"Approva"} &nbsp; <b>{presence.modified.shift_planning_centesimal_duration}</b> &nbsp; {"assegnate"}
                        </UIButton>
                      </Row>
                    </Col>
                  </>
                )
                  : 
                  <></> }

              </>)
               : (<></>)}
            </>
          )}
        </Col>
      </Row>
    </Drawer>
  );
};
